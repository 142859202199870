.dashboard-sidebar {
    &-icon {
        height: 20px;
        width: 20px;
        object-fit: contain;

        &-small {
            height: 21px;
            width: 21px;
            object-fit: contain;
        }
    }
}
.dashboard-sidebar-logo {
    width:  150px;
}
.dashboard-sidebar-link {
    padding: 10px 0px;
}
.dashboard-sidebar-link:hover {
    background: rgba(176, 236, 182);
    color: black;
}
.dashboard-sidebar-main-container {
    height: 100vh;
    background-color: #ffffff;
    box-shadow: 2px 0px 6px 2px rgba(198, 198, 198, 0.5);
    z-index: 9;
}
.dashboard-sidebar-check-icon {
    height: 14px;
    width: 14px;
    object-fit: contain;
    object-position: center;
}

.dashboard-sidebar-arrow {
    height: 15px;
    width: 15px;
    object-fit: contain;
}
.dashboard-sidebar-building-height{
    width: 100%;
    height: 68vh;
}
// @media only screen and (max-width: 1024px) {
//     .dashboard-sidebar-main-container {
//         height: 100%;
//     }
// }
