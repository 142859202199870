// .header-button-container{
//   position: absolute;
//   left:1vw;
// }
// .header-right-container{
//   position: absolute;
//   right:1vw;
// }
.mobile-icon{
  display:none !important;
}
.left-container{
  padding-left:1vw;
}
// .right-container{
//   width: 50% !important;
//   max-width: 50% !important;
// }
.header-style {
  background: #ffffff;
  box-shadow: 2px 0px 6px 2px rgba(198, 198, 198, 0.5);
  margin-bottom: 5px;
  height: 65px;
}

.header-image {
  width: 100px;
}
.header-button {
  &-blue{
    height: 45px;
  border-radius: 5px;
  }
  &-white{
    height: 45px;
    // width: 177px;z
    border-radius: 5px;
    margin-left: 10px;
    border: 2px solid #9b9b9b;
  }
  &-plus {
    height: 12px;
    width: 12px;
    object-fit: contains;
    color: gray;
  }
}
.header-badge {
  margin-left: -10px;
}
.header-profile-image {
  border-radius: 25px;
  height: 50px;
  width: 50px;
  object-fit: contain;
  cursor: pointer;
}

.header {
  &-bell {
    height: 26px;
    width: 22px;

    &-bubble {
        height: 16px;
        width: 16px;
        border-radius: 500px;
        //background: #7DA5C2;
        background: #5cb555;
        position: absolute;
        top: 0px;
        right: 0px;
    }
  }

  &-notification {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
    padding: 0px 16px;
    overflow-y: auto;

    &-container {
        height: 400px;
        width: 260px;
        position: absolute;
        top: calc(100% + 15px);
    }

    &-item {
        height: auto;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-border {
            @extend .header-notification-item;
            border-bottom: solid 1px #AAB7C4;
        }
    }

    &-icon {
        height: 15px;
        width: 15px;
        object-fit: contain;
        object-position: center;
        margin-right: 5px;
    }

    &-bubble {
        height: 8px;
        width: 8px;
        border-radius: 8px;
        //background: #7DA5C2;
        background: #5cb555;
        margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .left-container{
    width: 40% !important;
    max-width: 40% !important;
    flex-basis:unset !important
  }
  .right-container{
    width: 40% !important;
    max-width: 40% !important;
  }
  .mobile-icon{
    display:flex !important;
  }
  .header-bell-container{
    margin-right:calc(10px * var(--scale));
  }
  .header-notification-container {
    z-index:999;
  }
  .header-image {
    width: 100px;
  }
  .header-button {
    height: 40px;
    width: 100px;

  }
  .header-profile-image {
    height: 30px;
    width: 30px;
  }
}

.header {
  &-bell {
    height: 26px;
    width: 22px;

    &-bubble {
        height: 16px;
        width: 16px;
        border-radius: 500px;
        //background: #7DA5C2;
        background: #5cb555;
        position: absolute;
        top: 0px;
        right: 0px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .header-button-white  {
    margin-left: 0px;
    width: 97%;
  }
}
