.loader {
    &-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  &-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid #193e5d;;
    border-radius: 50%;
    animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #195d24 transparent transparent transparent;
  }
  &-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  &-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  &-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  &-modal {
      height: 100%;
      width: 100%;
      background: rgba(0,0,0,0);
      position: absolute;
      top: 0px;
      left: 0px;
  }
}
  @keyframes loader-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
