.dashboard-detail-padding {
  padding-top: 15px;
  padding-left: 35px;
  padding-right: 50px;
}

.dashboard-detail-company {
  &-button {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #DCDCDC;
    background-color: white;

    &-container {
      height: 45px;
      width: 45px;
      display: flex;
      justify-content: center;

    }
  }

  &-selected {
    height: 10px;
    width: 10px;
    border-radius: 5px;
    position: absolute;
    left: 0px;
  }

  &-modal {
    width: 250px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
    position: absolute;
    top: calc(100% + 15px);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;

    &-item {
      width: 90%;
      height: 35px;
      border-bottom: solid 1px #DCDCDC;
      cursor: pointer;
    }
  }

  &-active {
    &-container {
      height: 45px;
      border-radius: 10px;
      background: #193E5D;
      padding: 0px 15px;
      min-width: 100px;
    }
  }
}

.dashboard-detail-box-main {
  width: 220px;
  height: 171px;
  color: #ffffff;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.dashboard-detail-box-icon {
  background-color: #ffffff;
  border-radius: 50%;
  color: #758799;
  height: 51px;
  width: 51px;
}

.dashboard-detail-search-icon {
  height: 20px;
  width: 20px;
  object-fit: contain;
  margin-left: 15px;
}

.dashboard-detail-search {
  margin-left: 15px;
  &::placeholder {
    color: #AAB7C4;
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: #AAB7C4;
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
   color: #AAB7C4;
  }
}

.dashboard-detail-box-icon-color {
  //filter: invert(56%) sepia(19%) saturate(388%) hue-rotate(169deg)
    //brightness(89%) contrast(90%);
  height: 30px;
  width: 30px;
}
.dashboard-search {
  width: 95%;
  height: 45px;
  background: #ffffff;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px;
}
.dashboard-detail-card {
  background-color: #ffffff;
  padding: 20px;
  border: 1px solid #dedede;
  border-radius: 10px;
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.dashboard-detail-card-verticleLine {
  height: 100%;
  width: 1px;
  background-color: #dedede;
}

.dashboard-detail-card-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}

.dashboard-detail-unread-icon {
  height: 10px;
  width: 10px;
  background-color: #7da5c2;
  border-radius: 50%;
}

.dashboard-detail-more-option {
  &-container{
    position: absolute;
    right: 10px;
    top: 40%;
    width: 200px;
  }

  &-modal{
    height: auto;
    width: auto;
    padding: 10px;
    background-color: #ffffff;
    z-index: 2;
    box-shadow: rgba(74, 74, 74,0.5) 1px 1px 5px 1px;
    position: absolute;
    top: 100%;
    right: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
  }

  &-arrow{
    height: 15px;
    width: 23.78px;
    margin-right: 12.5px;
    z-index: 3;
  }
}

.dashboard-detail-more-option-row {
  width: 100%;
  height: 1px;
  border: 1px solid #4a4a4a;
  margin: 2% 0px 2% 0px;
}
.dashboard-detail-radio {
  &-outline {
    width: 12px;
    height: 12px;
    border: 2px solid #5cb555;
    border-radius: 10px;
    left: 10px;
    top: 10px;
  }
  &-filled {
    width: 12px;
    height: 12px;
    background: #7DA5C2;
    border-radius: 10px;
    left: 10px;
    top: 10px;
  }
}

.dashboard-detail {
  &-status-icon {
    width: 150px;
  }
  &-filter-btn {
    padding: 3%;
    width: 45px;
    height: 45px;

    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
  }
  &-filter-option {
    max-height: 200px;
    min-width: 200px;
    padding: 10px;
    background-color: #ffffff;
    z-index: 2;
    box-shadow: rgb(74, 74, 74) 1px 1px 5px 1px;
    position: absolute;
    top: calc(100% + 15px);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 10px;
    overflow-y: auto;
  }
  &-filter-icon {
    height: 15px;
    width: 15px;
    margin-left: 10px;
  }
  &-card-main {
    height: calc(100vh - 336px);
  }
}

@media only screen and (max-width: 900px) {
  .dashboard-detail-status-icon {
    width: 100px;
  }

  .dashboard-detail-padding {
    padding: 0px 10px;
  }

  .dashboard-search {
    width: 100%;
  }

  .dashboard-detail-card-main {
    height: calc(100vh - 275px);
  }

  .dashboard-detail {
    &-filter-option {
      right: 0px;
    }
  }
}
