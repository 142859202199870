.residence-amboise-list {
  width: 40px;
  height: 40px;
  margin: 10px;
  // margin-top: 20px;
  border: solid;
  border-width: thin;
  border-radius: 10px;
  border-color: #979797;
  background-color: #ffffff;
  // position: absolute;
}
.residence-amboise-hr {
  border: 1.5px solid #979797;
  border-radius: 10px;
  margin: 2px;
  width: 8px;
  background-color: grey;

  &-end-lines {
    width: 18px;
  }
  &-middle-line {
    width: 9px;
    // margin-left: -7px;
  }
}
// .residence-amboise-buttons-section {
//   margin-top: -30px;
// }
.residence-amboise-buttons-small {
  width: 100%;
  // height: 45px;
}
.residence-amboise-buttons-large {
  width: 100%;
  // height: 45px;
  font-size: 14px;
}
.residence-amboise-image {
  width: 80%;
  height: 80%;
}
.residence-amboise-line-style {
  &-vertical {
    width: 2px;
    height: 5vh;
    margin: 10px;
    border: 1px solid #aab7c4;
    background-color: #aab7c4;

    // transform: matrix(1,0, 0, 1, 0, 0);
  }
  &-first-left {
    width: 100%;

    border: 1px solid #aab7c4;
    background-color: #aab7c4;
    transform: matrix(1, 0.6, 0, 1, 0, 0);
  }

  &-second-left {
    width: 100%;
    border: 1px solid #aab7c4;
    background-color: #aab7c4;

    transform: matrix(-1, -0.2, 0, 1, 0, 0);
  }
  &-third-left {
    width: 100%;

    border: 1px solid #aab7c4;
    background-color: #aab7c4;

    transform: matrix(-1, 0.2, 0, 1, 0, 0);
  }
  &-fourth-left {
    width: 100%;
    border: 1px solid #aab7c4;
    background-color: #aab7c4;

    transform: matrix(-1, 0.6, 0, 1, 0, 0);
  }
  &-first-right {
    width: 100%;
    border: 1px solid #aab7c4;
    background-color: #aab7c4;

    transform: matrix(-1, 0.6, 0, 1, 0, 0);
  }
  &-second-right {
    width: 100%;

    border: 1px solid #aab7c4;
    background-color: #aab7c4;

    transform: matrix(1, -0.2, 0, 1, 0, 0);
  }
  &-third-right {
    width: 100%;

    border: 1px solid #aab7c4;
    background-color: #aab7c4;

    transform: matrix(1, 0.2, 0, 1, 0, 0);
  }
  &-fourth-right {
    width: 100%;
    height: 3px;
    left: 458px;
    top: 422.5px;

    border: 1px solid #aab7c4;
    background-color: #aab7c4;

    transform: matrix(1, 0.6, 0, 1, 0, 0);
  }
}
.residenc-amboise-end-line {
  width: 98%;
  height: 1px;
  border: 1px solid #aab7c4;
  background-color: #aab7c4;
}
.disable-button-style {
  width: 90%;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #bdbdbd;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.enable-button-style {
  width: 90%;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #4a4a4a;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.selected-button-style {
  width: 90%;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background: #2f8217;
  border: 1px solid #dadada;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.big-button-style {
  width: 90%;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #4a4a4a;
  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 10px;
  overflow-wrap: break-word;

}

.disabled-button-style {
  width: 90%;
  height: 44px;
  opacity: 0.2;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background: #9b9b9b;
  border: 1px solid #dadada;
  border-radius: 10px;
  overflow-wrap: break-word;
}

.enabled-button-style {
  width: 90%;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
  background: #7da5c2;
  border: 1px solid #dadada;
  border-radius: 10px;
  overflow-wrap: break-word;
}

@media only screen and (max-width: 600px) {
  .enable-button-style {
    width: 100%;
  }
  .disable-button-style{
    width: 100%;
  }
  .selected-button-style {
    width: 100%;

  }
  .big-button-style {
    width: 90%;

  }
}
