.document-details {
    &-dot {
      width: 8px;
      height: 8px;
      margin-left: -60px;
      margin-top: 12px;
      border-radius: 4px;
      background: #5cb555;
    }
    &-image{
      width: 21px;
      height: 28px;
      margin-left: 15px;
    }
    &-file-name {
      width: 90%;
      font-size: 14px;
      font-weight: 500;
      color: #000000;
      overflow-wrap: break-word;
    }
    &-person-name {
      width: 100%;
      font-size: 14px;
      color: #4a4a4a;
      overflow-wrap: break-word;
    }
    &-label {
      width: 100%;
      font-size: 14px;
      color: #4a4a4a;
      overflow-wrap: break-word;
    }
    &-date-time {
      width: 100%;
      font-size: 14px;
      color: #4a4a4a;
      overflow-wrap: break-word;
    }

    &-dropzone-container {
      min-height: 100px;
      // height: 500px;
      width: 100%;
      margin-top: 25px;
    }
  }
  .document-details {
    &-outer-container {
      height: 30vh;
      width: 100%;
      overflow: hidden;
    }
    &-inner-container {
      height: 30vh;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
  .slider-thumb {
    -webkit-appearance: none;
    width: 15px;
    height: 20px;
    background:#5cb555;
    cursor: pointer;
  }

 .slider {
    -webkit-appearance: none;
    width: 200px;
    height: 20px;
    border-radius: 3px;
    border: 1px solid #5cb555;;
    outline: none;
    margin: 0px 10px 0px 10px;
  }
