.project-application-radio {
  &-outline {
    width: 19px;
    height: 19px;
    border: 1.5px solid #5cb555;
    border-radius: 10px;
  }
  &-filled {
    width: 19px;
    height: 19px;
    background: #5cb555;
    border-radius: 10px;
  }
}
