
.project-application-service-unselected {
  background-color: #ffffff;
  border-radius: 10px;
  width: 100%;
  height: 58px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-right: 50px;
  margin-bottom: 10px;
  border: 1px solid #e1e1e1;
  cursor: pointer;
}
.project-application-service-unselected:hover {
  color: #ffffff;
  background: #5cb555;
  box-shadow: 0px 0px 11px 1px rgba(120, 120, 120, 0.5);
}
.project-application-service-selected {
  background: #5cb555;
  box-shadow: 0px 0px 11px 1px rgba(120, 120, 120, 0.5);
  border-radius: 10px;
  color: #ffffff;
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-right: 50px;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.project-application-service-selectedIcon {
  height: 20px;
  width: 20px;
  top: -8px;
  position: absolute;
  left: 96%;
  justify-self: flex-end;
  border: 1px solid #ffffff;
  border-radius: 50%;
  padding: 3px;
  background-color: #5cb555;
  color: #ffffff;
}
.project-application-service-size {
  width: 100%;
  height: auto;
}
.project-application-service-line-between{
  width: 2px;
  height: 100%;
  margin-right: 50px;
  background-color: rgba(0,0,0, 0.1);
}

.project-application-service-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: auto;
}

.project-application-service-card-container-one {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
}

.project-application-service-card-container-two {
  width: 300px;
  display: flex;
  flex-wrap: wrap;
}

.project-application-button-container {
  width: 50%;
}

@media only screen and (max-width: 960px) {
  .project-application-submit button {
    width: 100% !important;
  }
  .project-application-service-size {
    height: auto;
  }
  .project-application-service-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .project-application-service-line-between{
    width: 93%;
    height: 2px;
    margin: 25px 0px;
    background-color: rgba(0,0,0, 0.1);
  }



  .project-application-service-card-container-one {
    width: 100%;
  }

  .project-application-service-card-container-two {
    width: 100%;
  }

  .project-application-button-container {
    display: flex;
    justify-content: center;
    width: 90%;
    margin-left: 10px;
  }

  .project-application-submit {
    width: 100%;
  }
}

.project-application-submit {
  margin-top: 50px;
  margin-bottom: 35px;
}

@media only screen and (max-width:600px){
  .project-application-service-unselected {
    margin-right: 0px;
  }

  .project-application-service-selected {
    margin-right: 0px;
  }
  .project-application-button-container {
    width: 100%;
  }

  .project-application-service-line-between{
    width: 100%;
  }

}
