@import '../scss/design.scss';
@import './animated.scss';

// partial imports here, partial imports are files that start with an _
// example
// @import " ./homepage/_homepage.scss"
@import '../components/Header/profile_view/_profile_view.scss';
@import '../components/loginpage/_loginpage.scss';
@import '../components/dashboard/_dashboard.scss';
@import '../components/dashboard/dashboard_detail/_dashboard_detail.scss';
@import '../components/dashboard/dashboard_sidebar/_dashboard_sidebar.scss';
@import '../components//project_application/_project_application.scss';
@import '../components//project_application/project_application_service/_project_application_service.scss';
@import '../components/project_application/project_application_information/_project_application_information.scss';
@import '../components/project_application/project_application_buildingtype/_project_application_buildingtype.scss';
@import '../components/project_application/project_application_contactinfo/_project_application_contactinfo.scss';
@import '../components/project_application/step_seven/_step_seven.scss';
@import './project_application/step_five/step_five_a/_step_five_a.scss';
@import './project_application/step_five/_step_five.scss';
@import '../components/residence/_residence.scss';
@import '../components/residence/components/actions_information/_actions_informations.scss';
@import '../components/residence/components/residence_amboise/_residence_amboise.scss';
@import '../components/residence/components/residence_amboise_actions/_residence_amboise_actions.scss';
@import '../components/residence/components/actions_information/document_details/_document_details.scss';
@import '../components/residence/components/actions_information/document_details/row_details/_row_details.scss';
@import '../components/residence/components/actions_information/emails_tab/_emails_tab.scss';
@import '../components/residence/components/actions_information/info_tab/_info_tab.scss';
@import '../components/shared_components/loader/_loader.scss';
@import '../components/epb_forms/_epb_service.scss';
@import './shared_components/custom_input/_custom_input.scss';
@import '../components/dashboard/message_panel/_messagepanel.scss';
@import '../components/dashboard/documents/_documents.scss';
@import '../components/epb_forms/_epb_service.scss';
@import '../components/epb_forms/step_one/_step_one.scss';
@import '../components/epb_forms/step_two/_step_two.scss';
@import '../components/epb_forms/step_three/_step_three.scss';
@import '../components/epb_forms/step_four/_step_four.scss';
@import '../components/epb_forms/step_five/_step_five.scss';
@import '../components/shared_components/error/_error.scss';
@import '../components/safety_service/step_one/_step_one.scss';
@import '../components/ventilation_service/_ventilation_service.scss';
@import '../components/ventilation_service/step_1/_step_1.scss';
@import '../components/ventilation_service/step_2/_step_2.scss';
@import '../components/ventilation_service/step_3/_step_3.scss';
@import '../components/shared_components/custom_popup/_custom_popup.scss';
@import '../components/dashboard/message_panel/message_mobile/_message_mobile.scss';

/* [NOTE]: Pease remove this :root {} declaration.
This has been added to  `styles/global.css.js
`*/

// :root {
//     --scale: 1;
//     --scaleHeight: 1;
// }

.font-color {
  &-grey {
    color: #9b9b9b;
  }

  &-dark-grey {
    color: #4a4a4a;
  }

  &-light-grey {
    color: #bdbdbd;
  }

  &-semi-dark-grey {
    color: #6f6f6f;
  }

  &-sky-blue {
    //color: #7da5c2;
    color: #5cb555;
  }

  &-navy-blue {
    //color: #193e5d;
    color: #4a4a4a;

    &-faded {
      @extend .font-color-navy-blue;
      opacity: 0.7;
    }
  }

  &-white {
    color: #ffffff;
  }

  &-black {
    color: #000000;
  }

  &-red {
    color: #f30c0c;
  }

  &-standard-grey {
    color: #6f6f6f;
  }

  &-steel-blue {
    color: #aab7c4;
  }
}

.background-color {
  &-grey {
    background-color: #9b9b9b;
  }

  &-dark-grey {
    background-color: #4a4a4a;
  }

  &-light-grey {
    background-color: #bdbdbd;
  }

  &-semi-dark-grey {
    background-color: #6f6f6f;
  }

  &-sky-blue {
    //background-color: #7da5c2;
    background-color: #5cb555;
  }

  &-navy-blue {
    background-color: #193e5d;
  }

  &-white {
    background-color: #ffffff;
  }

  &-black {
    background-color: #000000;
  }

  &-red {
    background-color: #f30c0c;
  }

  &-transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  &-greyish-white {
    background-color: #f8f8f8;
  }
}

.core-custom {
  &-button {
    line-height: 1.5rem;
    border-radius: 4px;
    padding: 8px 6px;
    //background-color: #7da5c2;
    background-color: #11a257;
    color: #ffffff;
    font-size: 14px;
    width: 100%;
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #7da5c2;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
